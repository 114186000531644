











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<StockKeepingUnit>({
  name: 'StockKeepingUnit'
})
export class StockKeepingUnit extends Vue {
  @Prop({ type: Number, required: true })
  public readonly sku!: number
}

export default StockKeepingUnit
