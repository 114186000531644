






































import { Component, Inject as VueInject, Mixins, Prop, Ref } from 'vue-property-decorator'

import { DeliveryInfo } from '../../../../../contexts/shared/contracts/deliveryInfo'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { DeliveryDateMixin, IDeliveryDate } from '../../../../shared/mixins/delivery-date.mixin'
import TopBarMixin from '../../../../shared/mixins/topBar.mixin'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<DeliveryTimer>({
  name: 'DeliveryTimer',
  mounted (): void {
    this.initTimer()

    if (this.showTimer) {
      this.interval = setInterval(() => {
        this.calculateDeliveryTime()
      }, 1000)

      this.$nextTick(this.setHeight)
    }
  },
  destroyed (): void {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
})
export class DeliveryTimer extends Mixins<IDeliveryDate, TopBarMixin>(
  DeliveryDateMixin,
  TopBarMixin
) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: true })
  public stockStatus!: boolean

  @Prop({ type: String, required: true })
  public limitHours!: string

  @Ref('wrapper')
  public readonly wrapperRef?: HTMLDivElement

  public deliveryTime: DeliveryInfo | null = null
  public interval?: ReturnType<typeof setInterval> | null

  protected limitTime: number | null = null

  public get deliveryDay (): string {
    return this.getDeliveryWeekdayLabel().toLowerCase()
  }

  public get deliveryWeekday (): string {
    const weekday = this.deliveryFrom?.getDay()
    const day = this.$t(`front.products.organisms.productHeader.deliveryTimer.weekday.${weekday}`)
    const possibleLabels =
      this.$t('front.products.organisms.productHeader.deliveryTimer.shipmentInfo')

    if (!Array.isArray(possibleLabels)) {
      return ''
    }

    const i = Math.floor(Math.random() * possibleLabels.length)

    return this.$t(
      `front.products.organisms.productHeader.deliveryTimer.shipmentInfo.${i}`,
      { day }
    ).toString() ?? ''
  }

  public get showTimer (): boolean {
    if (!this.hasTimer || !this.limitTime) {
      return false
    }

    return Date.now() < this.limitTime
  }

  public calculateDeliveryTime (): void {
    if (!this.limitTime) {
      return
    }

    const now = Date.now()
    const remainingTime = (this.limitTime - now) / 1000

    this.deliveryTime = {
      hours: Math.floor(remainingTime / 60 / 60),
      minutes: Math.floor((remainingTime / 60) % 60),
      seconds: Math.floor(remainingTime % 60)
    }
  }

  public initTimer (): void {
    const limit = new Date()

    limit.setHours(this.maxOrderHours)
    limit.setMinutes(0)
    limit.setSeconds(0)

    this.limitTime = limit.getTime()

    this.calculateDeliveryTime()
  }

  public pad (value: number): string {
    return (`0${value}`).slice(-2)
  }

  private setHeight (): void {
    const root: HTMLElement = document.documentElement
    if (!root) {
      return
    }
    const height = this.wrapperRef ? this.wrapperRef.offsetHeight : 0

    root.style.setProperty('--product-top-bar-height', `${height}px`)
  }
}

export default DeliveryTimer
