











import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  AllowedAttributes,
  AttributeValue
} from '../../../../../contexts'

@Component<AttributesList>({
  name: 'AttributesList'
})
export class AttributesList extends Vue {
  @Prop({ type: Object, required: true })
  public readonly attributes!: Record<AllowedAttributes, AttributeValue | AttributeValue[]>

  public getValueLabel (key: AllowedAttributes, value: AttributeValue | AttributeValue[]) {
    if (typeof value === 'number') {
      return this.$tc(`front.products.organisms.productHeader.listedAttributes.${key}.value`, value, { value })
    }

    return this.$t(`front.products.organisms.productHeader.listedAttributes.${key}.value`, { value })
  }
}

export default AttributesList
