











import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../contexts'

@Component<OrderDetails>({
  name: 'OrderDetails'
})
export class OrderDetails extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Object, required: true })
  public orderDetails!: Record<string, string>

  public get freeDeliveryFrom (): number | null {
    const freeDeliveryFrom = this.siteService?.getProperty('freeDeliveryFrom') as number | null

    if (!freeDeliveryFrom) {
      return null
    }

    return this.$options.filters!.currency(freeDeliveryFrom)
  }
}

export default OrderDetails
