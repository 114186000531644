







































































import { Component, Inject as VueInject, Mixins, Prop } from 'vue-property-decorator'

import { calculateDiscount, defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { ProductData, Variant as ProductVariant } from '../../../../../contexts'
import { MilesAndMoreCounter } from '../../../../loyalty/molecules/MilesAndMoreCounter'
import { AuthMixin, IAuthMixin } from '../../../../auth/shared'
import {
  translateProductVariantsToVariantsSwitch,
  Variant,
  VariantsSwitch
} from '../../../molecules/VariantsSwitch'

import { Slug } from '../ProductHeader.contracts'

@Component<Variants>({
  name: 'Variants',
  components: {
    MilesAndMoreCounter,
    VariantsSwitch
  }
})
export class Variants extends Mixins<IAuthMixin>(AuthMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  public readonly currentVariantSlug!: Slug | null

  @Prop({ type: Object, required: true })
  public variant!: ProductVariant<string>

  @Prop({ type: Boolean, required: true })
  public hasDiscount!: boolean

  @Prop({ type: Boolean, required: true })
  public hasMilesAndMore!: boolean

  @Prop({ type: Boolean, required: true })
  public isVariantNameEnabled!: boolean

  @Prop({ type: Boolean, required: true })
  public variantSwitcherShowChosen!: boolean

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  /**
   * Determines the capacity based attributes of the variant (ex: volume, weight)
   */
  public get displayableCapacityAttributes (): string[] {
    const toReturn: string[] = []

    if (this.shouldDisplayVolume) {
      toReturn.push('volumeName')
    }

    if (this.shouldDisplayWeight) {
      toReturn.push('weightName')
    }

    return toReturn
  }

  public get hasAdvancedVariants (): boolean {
    if (!this.product.variantSelector) {
      return false
    }

    return Object.keys(this.product.variantSelector).some(v => this.variantsSwitchType(v) ===
      'advanced')
  }

  public get hasVariants (): boolean {
    return Object.keys(this.product.variants).length > 1
  }

  public get shouldDisplayVolume (): boolean {
    return !!this.variant.attributes.volumeName
  }

  public get shouldDisplayWeight (): boolean {
    return !!this.variant.attributes.weightName
  }

  public calculatedDiscount (finalPrice: number, regularPrice: number): string {
    return calculateDiscount(finalPrice, regularPrice)
  }

  public variantsSwitchType (key: string): string {
    if (!this.product.variantSelector || !(key in this.product.variantSelector)) {
      throw new Error('key not found')
    }

    const elements = this.product.variantSelector[key]

    if (elements.length === 0) {
      return ''
    }

    if (elements.some(({ value }) => !!value)) {
      return 'default'
    }

    return 'advanced'
  }

  public variantsSwitchProps (type = 'color'): Variant[] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  public onUpdateVariant (slug: string, type = 'color') {
    if (!this.currentVariantSlug) {
      throw new Error('Variant slug not set')
    }

    const currentSlug = this.currentVariantSlug
    const newSlug: string[] = []
    const selectorKeys = Object.keys(this.product.variantSelector || {})
    selectorKeys.forEach((key) => {
      if (key === type) {
        newSlug.push(slug)
        return
      }

      newSlug.push(currentSlug[key])
    })

    this.currentVariantSlug[type] = slug
    slug = newSlug.join('-')

    this.$emit('setVariant', slug)
  }

  public variantLabel (key: string, variant: ProductVariant<string>): string | undefined {
    if (!this.product.variantSelector) {
      return ''
    }

    return this.product.variantSelector[key].find((selector) => {
      return (selector && 'slug' in selector) && selector.slug === variant.identifier[key]
    })?.label
  }
}

export default Variants
