











import { Component, Prop, Vue } from 'vue-property-decorator'
import { Application } from '../ProductHeader.contracts'

@Component<ApplicationOptions>({
  name: 'ApplicationOptions'
})
export class ApplicationOptions extends Vue {
  @Prop({ type: String, required: true })
  public application!: string

  /**
   * Determines the application of current product
   */
  protected get applicationOptions (): string[] | null {
    if (typeof this.application === 'undefined') {
      return null
    }

    if (this.application.includes('/')) {
      return this.application.split('/')
    }

    return [this.application]
  }

  /**
   *
   * TODO: Should be operated by key, not by text.
   * Gets the application of the active variant.
   */
  public getApplication (application: string): undefined | string {
    const day = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.day}`)
    const night = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.night}`)
    const dayNight = this.$t(`front.products.organisms.productHeader.attributes.application.${Application['day/night']}`)
    switch (application) {
      case day:
        return 'DayIcon'
      case night:
        return 'NightIcon'
      case dayNight:
        return 'DayNightIcon'
    }
  }
}

export default ApplicationOptions
