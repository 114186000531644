










































































































































































































































































import { Component, Inject as VueInject, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  IModal,
  mapModel,
  ModalType
} from '@movecloser/front-core'

import { ShapeMap, SizeMap, VariantMap } from '../../../../dsl/composables'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import {
  AllowedAttributes,
  AttributeValue,
  ProductData,
  Variant as ProductVariant
} from '../../../../contexts'
import { StarsRateProps } from '../../../../dsl/molecules/StarsRate'

import { ProductReviewsID } from '../../../../modules/ProductReviews/ProductReviews.config'

import { BenefitsBar } from '../../../shared/molecules/BenefitsBar'
import {
  DrawerType,
  IDrawer,
  IStoreService,
  StoreServiceType
} from '../../../shared/contracts/services'
import { Gallery } from '../../../shared/molecules/Gallery'
import { GalleryProps } from '../../../shared/molecules/Gallery/Gallery.contracts'
import { MilesAndMoreCounter } from '../../../loyalty/molecules/MilesAndMoreCounter'
import { openAuthDrawer, UserModel } from '../../../auth/shared'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'
import {
  translateProductVariantToGalleryProps
} from '../../../shared/molecules/Gallery/Gallery.helpers'
import WindowScrollMixin from '../../../shared/mixins/windowScroll.mixin'

import { attributesAdapterMap } from '../../models/attributes.adapter'
import { FavouriteProductsServiceType, IFavouriteProductsService } from '../../contracts/services'
import { Modals } from '../../config/modals'
import { NotificationForm } from '../../molecules/NotificationForm'
import {
  IProductsRepository, IProductStockRepository,
  ProductsRepositoryType,
  ProductStockRepositoryType
} from '../../contracts/repositories'
import { translateProductVariantToStarsRateProps } from '../../helpers/start-rate'

import { isAttribute } from '../ProductCard/ProductCard.helpers'

import AllowedAttributesIcons from './partials/AllowedAttributesIcons.vue'
import { GiftBox } from './partials/GiftBox.vue'
import { StockKeepingUnit } from './partials/StockKeepingUnit.vue'
import {
  ProductHeaderIcons,
  ProductHeaderProps,
  ShippingTimerData,
  Slug
} from './ProductHeader.contracts'
import {
  PRODUCT_HEADER_COMPONENT_KEY,
  PRODUCT_HEADER_DEFAULT_CONFIG,
  ProductBadgeProps,
  ProductHeaderBadgeRegistry
} from './ProductHeader.config'
import DeliveryTimer from './partials/DeliveryTimer.vue'
import OrderDetails from './partials/OrderDetails.vue'

import PriceNameBar from './partials/PriceNameBar.vue'
import { BaseWishListMixin, IBaseWishListMixin } from '../../../wishlist/shared/mixins/base.mixin'
import { showDeliveryTimer } from '../../../shared/support/delivery-timer'
import VariantDetailsRating from './partials/VariantDetailsRating.vue'
import ApplicationOptions from './partials/ApplicationOptions.vue'
import Variants from './partials/Variants.vue'
import SimplePrice from './partials/SimplePrice.vue'
import { StructureConfigurable } from '../../../../support/mixins'
import { TrustedShopReviewsMixin } from '../../../shared/mixins/trustedShopReviewsMixin'
import AttributesList from './partials/AttributesList.vue'
import { toImageProps } from '../../../shared/support'
import Price from '../../../shared/molecules/Price/Price.vue'
import B2BProductMixin from '../../../shared/mixins/b2b-product.mixin'
import { QuantityInput } from '../../../shared/organisms/QuantityInput'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ProductHeader>(
  {
    name: 'ProductHeader',
    components: {
      AllowedAttributesIcons,
      ApplicationOptions,
      AttributesList,
      BenefitsBar,
      DeliveryTimer,
      GiftBox,
      OrderDetails,
      Gallery,
      MilesAndMoreCounter,
      NotificationForm,
      Price,
      PriceNameBar,
      QuantityInput,
      SimplePrice,
      StockKeepingUnit,
      VariantDetailsRating,
      Variants
    },
    async created (): Promise<void> {
      this.config = this.getComponentConfig(
        PRODUCT_HEADER_COMPONENT_KEY,
        { ...PRODUCT_HEADER_DEFAULT_CONFIG }
      )
      this.setVariantOnMount()
      this.initShowTimer()
      await this.checkGiftsAvailability()

      if (this.useVendorReviews) {
        await this.loadReviewsBySku()
      }

      await this.checkVariantAvailability()
    },
    mounted (): void {
      this.eventBus.emit('app:product.view', this.getProductViewPayload(this.variant))

      this.checkIsFavoriteVariant()
      this.createPriceNameObserver()
    }
  })

export class ProductHeader extends Mixins<
  ProductCartMixin & StructureConfigurable & TrustedShopReviewsMixin & ToastMixin & WindowScrollMixin
  & IBaseWishListMixin & B2BProductMixin
>(
  ProductCartMixin,
  StructureConfigurable,
  TrustedShopReviewsMixin,
  ToastMixin,
  WindowScrollMixin,
  BaseWishListMixin,
  B2BProductMixin
) implements ProductHeaderProps {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isReadonly!: boolean

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  @Prop({ type: Object, required: false, default: null })
  public readonly shippingTimer!: ShippingTimerData

  @Inject(AuthServiceType, false)
  protected readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(ProductStockRepositoryType)
  private productStockRepository!: IProductStockRepository

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(StoreServiceType, false)
  protected readonly storeService?: IStoreService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Ref('productHeader')
  public productHeaderRef!: HTMLDivElement

  @Ref('productHeaderContent')
  public productHeaderContentRef!: HTMLDivElement

  public toImageProps = toImageProps

  public addToFavoriteBtnLoading: boolean = false
  public addToCartBtnLoading: boolean = true
  public currentQuantity: number | null = null
  public currentVariantSlug: Slug | null = null
  public currentVariant: ProductVariant<string> | null = null
  public hasUnavailableGifts: boolean = false
  public isFavorite: boolean | undefined = false
  public isNotificationFormVisible: boolean | undefined = false
  public ratingAmountDefault: number = 0
  public limitHours: string | null = null
  public renderPriceNameBar: boolean = false
  public showTimer: boolean = false
  public stockStatus: number | null = null

  // todo: aelia 4
  public readonly LAST_ITEMS_AMOUNT: number = 3

  public readonly TOOLTIP_DELIVERY_INFO: string = this.$t(
    'front.products.organisms.productHeader.tooltipInfoDelivery').toString()

  public get addToCartLabel (): string {
    if (this.stockStatus === 0) {
      return this.$t('front.products._common.unavailable').toString()
    }

    if (this.isInCart) {
      return this.$t('front.products._common.updateInCart').toString()
    }

    return this.$t('front.products._common.addToCart').toString()
  }

  public get application (): string | undefined {
    return this.getAttribute<string>(AllowedAttributes.Application)
  }

  public get attributesToList (): Record<AllowedAttributes, AttributeValue | AttributeValue[]> | null {
    if (!this.listedAttributes || !this.listedAttributes.length) {
      return null
    }

    let attributes = {} as Record<AllowedAttributes, AttributeValue | AttributeValue[]>

    this.listedAttributes.forEach(attr => {
      if (this.isReadonly && this.shopOnlyAttributes?.includes(attr)) {
        return
      }

      const value = this.getAttribute(attr)

      if (typeof value !== 'undefined') {
        // attribute found in variant.attributes
        attributes[attr] = value
      } else if (attr in this.variant) {
        // attribute is a key directly in variant
        attributes[attr] = this.variant[attr as unknown as keyof ProductVariant<string>] as AttributeValue ?? ''
      }
    })

    if (this.doubleCheckStockStatus && Object.prototype.hasOwnProperty.call(attributes, 'sellableQuantity')) {
      attributes = {
        ...attributes,
        [AllowedAttributes.SellableQuantity]: this.stockStatus ?? 0
      }
    }

    return attributes
  }

  public get averageTrustedShopsProductRating (): number {
    return this.$store.getters['products/getAverageProductRating']
  }

  public get badgeRegistry (): ProductHeaderBadgeRegistry | undefined {
    return this.getConfigProperty<ProductHeaderBadgeRegistry>('badgeRegistry')
  }

  public get badges (): ProductBadgeProps[] {
    if (!this.badgeRegistry) {
      return []
    }

    const badges = []

    if (this.isFinalPriceDifferent) {
      if (this.shouldDisplayDiscount) {
        const theme = this.promotionBadgeHasLabel ? 'danger' : 'primary'
        const discountValue = `-${100 - (Math.round((this.variant.price.finalPrice /
          this.variant.price.regularPrice) * 100))}%`
        const label = this.promotionBadgeHasLabel ? discountValue : this.$t(
          'front.products.organisms.productHeader.attributes.isPromotion').toString()
        const shape = this.promotionBadgeHasLabel ? ShapeMap.Square : ShapeMap.Rectangle

        badges.push({
          label,
          theme,
          shape,
          variant: VariantMap.Full,
          size: SizeMap[this.isMobile() ? 'Small' : 'Large']
        })
      } else {
        if (this.getAttribute('isSale')) {
          badges.push({
            ...this.getBadge(AllowedAttributes.IsSale),
            label: this.$t('front.products.organisms.productHeader.attributes.isSale').toString()
          })
        }
      }
    }

    if (this.getAttribute('isNatural')) {
      badges.push(this.getBadge(AllowedAttributes.IsNatural))
    }

    if (this.getAttribute('hasFreeDelivery')) {
      badges.push(this.getBadge(AllowedAttributes.HasFreeDelivery))
    }

    if (this.getAttribute('isNew')) {
      badges.push({
        ...this.getBadge(AllowedAttributes.IsNew),
        label: this.$t('front.products.organisms.productHeader.attributes.isNew').toString()
      })
    }

    if (this.getAttribute('isFaF')) {
      badges.push({
        ...this.getBadge(AllowedAttributes.IsFaF),
        label: this.$t('front.products.organisms.productHeader.attributes.isFaF').toString()
      })
    }

    if (this.getAttribute('isPresale')) {
      badges.push({
        ...this.getBadge(AllowedAttributes.IsPresale),
        label: this.$t('front.products.organisms.productHeader.attributes.isPresale').toString()
      })
    }

    return badges
  }

  /**
   * Determines whether product header gallery has badges.
   */
  public get badgesOnGallery (): boolean {
    return this.getConfigProperty('badgesOnGallery')
  }

  public get canAddToCart (): boolean {
    return this.variant.isAvailable && this.variant.sellableQuantity > 0
  }

  public get cartQuantity (): number {
    return this.quantityInCart(this.currentVariant?.sku)
  }

  public get defaultMaxRating (): number {
    return this.getConfigProperty('defaultMaxRating')
  }

  public get doubleCheckStockStatus (): boolean {
    return this.getConfigProperty('doubleCheckStockStatus')
  }

  public get descriptionAttribute (): string | undefined {
    const attr: AllowedAttributes | undefined = this.getConfigProperty('descriptionAttribute')

    if (!attr) {
      return
    }

    // todo: attribute value can be an object
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.variant.attributes[attr]?.label
  }

  public get deliveryTimeInfo () {
    if (!this.storeService) {
      return null
    }

    try {
      const info = this.storeService.deliveryInfo
      const day = this.$t(`front.products.organisms.productHeader.infoBarEntryDays.${info.day}`)

      return {
        ...info,
        day
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    }
  }

  public get favouriteAsIcon (): boolean {
    return this.getConfigProperty('favouriteAsIcon')
  }

  public get galleryProps (): GalleryProps {
    return {
      ...translateProductVariantToGalleryProps(this.variant),
      badges: this.badges
    }
  }

  public get hasBenefitsBar (): boolean {
    return this.getConfigProperty('hasBenefitsBar')
  }

  public get hasMilesAndMore (): boolean {
    return this.getConfigProperty('hasMilesAndMore')
  }

  /**
   * Determines whether product header has delivery timer.
   */
  public get hasDeliveryTimer (): boolean {
    return this.getConfigProperty('hasDeliveryTimer')
  }

  public get hasDiscount (): boolean {
    if (!this.variant) {
      return false
    }
    const {
      finalPrice,
      regularPrice
    } = this.variant.price

    return finalPrice < regularPrice
  }

  public get hasGiftsBox (): boolean {
    return this.getConfigProperty('hasGiftsBox') &&
      this.currentVariant?.attributes[AllowedAttributes.HasGift] as boolean &&
      this.hasUnavailableGifts
  }

  /**
   * Determines whether product header has a "notify me" button.
   */
  public get hasNotificationForm (): boolean {
    return this.getConfigProperty('hasNotificationForm')
  }

  public get hasPriceInAddToCart (): boolean {
    return this.getConfigProperty('hasPriceInAddToCart')
  }

  public get hasPriceNameBar (): boolean {
    return this.getConfigProperty('hasPriceNameBar')
  }

  public get hasQuantity (): boolean {
    return this.getConfigProperty('hasQuantity')
  }

  public get hasRating (): boolean {
    return this.getConfigProperty('hasRating')
  }

  public get highlightedAttributes (): AllowedAttributes[] | null {
    return this.getConfigProperty('highlightedAttributes')
  }

  public get icons (): ProductHeaderIcons {
    return this.getConfigProperty('icons')
  }

  public get isAddToCartDisabled (): boolean {
    return (this.variant.attributes.isGift as boolean) ||
      (this.variant.attributes.isSample as boolean) ||
      (this.hasQuantity && this.cartQuantity === 0 && this.quantity === 0)
  }

  public get isFavoriteVariant (): boolean | undefined {
    return this.isFavorite
  }

  /**
   * Determines the whether final price is different.
   */
  public get isFinalPriceDifferent (): boolean {
    return this.hasDiscount
  }

  public get isInCart (): boolean {
    return !!this.quantityInCart(this.currentVariant?.sku)
  }

  public get isVariantNameEnabled (): boolean {
    return this.getConfigProperty('isVariantNameEnabled')
  }

  public get listedAttributes (): AllowedAttributes[] | null {
    return this.getConfigProperty('listedAttributes')
  }

  public get modalSize (): string {
    return this.getConfigProperty<string>('modalSize')
  }

  /**
   * Determines whether product header has order details.
   */
  public get orderDetails (): boolean {
    return this.getConfigProperty('orderDetails')
  }

  public get productReviewsIdentifier () {
    return ProductReviewsID
  }

  /**
   * Determines whether promotion badge has label with discount value.
   */
  public get promotionBadgeHasLabel (): boolean {
    return this.getConfigProperty('promotionBadgeHasLabel')
  }

  public get quantity (): number {
    if (this.currentQuantity === null) {
      return this.quantityInCart(this.currentVariant?.sku)
    }

    return this.currentQuantity
  }

  public set quantity (value: number) {
    this.currentQuantity = value
  }

  public get quantityStep (): number {
    return this.getQuantityStep(this.variant)
  }

  public get ratingAmount (): number {
    return this.variants.map((variant) =>
      variant.rating?.average.amount ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get ratingAvg (): number {
    return this.variants.map((variant) =>
      variant.rating?.average.rate ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get shopOnlyAttributes (): AllowedAttributes[] | null {
    return this.getConfigProperty('shopOnlyAttributes')
  }

  public get shouldDisplayDiscount (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayDiscount')
  }

  public get shouldDisplayMainCategory (): boolean {
    if (!this.getAttribute('mainCategory')) {
      return false
    }

    if (this.shouldDisplayMainCategoryLogo) {
      return !!this.product.category.logo
    }

    return true
  }

  public get shouldDisplayMainCategoryLogo (): boolean {
    return this.getConfigProperty('displayMainCategoryLogo')
  }

  public get shouldDisplayNetto (): boolean {
    return this.getConfigProperty('shouldDisplayNetto')
  }

  public get shouldDisplayOmnibus (): boolean {
    return this.getConfigProperty('shouldDisplayOmnibus')
  }

  /**
   * Determines whether description should be visible in the `AddReviewModal`
   */
  public get shouldDisplayReviewWithDescription (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayReviewWithDescription')
  }

  public get shouldDisplayRegularPriceForDiscount (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayRegularPriceForDiscount')
  }

  public get shouldDisplaySimplePrice (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplaySimplePrice')
  }

  public get shouldDisplayUnits (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayUnits')
  }

  /**
   * Determines whether rating should be formated as: "rate / maximum rate" instead of "rate (maximum rate)"
   */
  public get shouldHaveSeparatedRating (): boolean {
    return this.getConfigProperty<boolean>('shouldHaveSeparatedRating')
  }

  public get shouldHaveVisibleRating (): boolean {
    return this.getConfigProperty('shouldHaveVisibleRating')
  }

  /**
   * Determines whether to show rating only for current variant instead of aggregate
   */
  public get showSingleVariantRating (): boolean {
    return this.getConfigProperty('showSingleVariantRating')
  }

  public get starsRateProps (): Omit<StarsRateProps, 'model'> {
    return translateProductVariantToStarsRateProps(this.variant, false)
  }

  public get useDrawer (): boolean {
    return this.getConfigProperty<boolean>('useDrawer')
  }

  /**
   * Determines whether to use review from external vendor
   */
  public get useVendorReviews (): boolean {
    return this.getConfigProperty('useVendorReviews')
  }

  public get variant (): ProductVariant<string> {
    if (!this.currentVariant) {
      throw new Error('Variant not set')
    }

    return {
      ...this.currentVariant,
      attributes: mapModel(this.currentVariant.attributes, attributesAdapterMap, false)
    }
  }

  public get variantBrandUrl (): string {
    return this.variant.attributes[AllowedAttributes.BrandUrl]?.toString() ?? ''
  }

  public get variantCategoryUrl (): string {
    return this.variant.attributes[AllowedAttributes.MainCategoryUrl].toString()
  }

  public get variantLastItems (): boolean {
    return this.variant.sellableQuantity <= this.LAST_ITEMS_AMOUNT
  }

  public get variantRating (): number {
    return this.variant.rating?.average.rate ?? this.ratingAmountDefault
  }

  public get variants (): ProductVariant<string>[] {
    return Object.values(this.product.variants)
  }

  /**
   * Determines whether chosen variant value is showed.
   */
  public get variantSwitcherShowChosen (): boolean {
    return this.getConfigProperty('variantSwitcherShowChosen')
  }

  public get wishlistBtnTitle (): string {
    return this.$t(`front.shared.wishlist.${this.isFavorite ? 'remove' : 'add'}`).toString()
  }

  public async addToFavorite (): Promise<void> {
    this.addToFavoriteBtnLoading = true

    try {
      await this.add({
        sku: this.variant.sku,
        quantity: 1
      })
      this.isFavorite = true
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  public authCheck (): boolean {
    return this.authService?.check() || false
  }

  public async checkIsFavoriteVariant (): Promise<void> {
    if (!this.variant || typeof this.variant === 'undefined') {
      return
    }

    this.isFavorite = this.isInWishlist(this.variant.sku)
  }

  public generateCategoryLink (categoryTree: AnyObject, name: string): string {
    if (categoryTree && categoryTree.parent) {
      return this.generateCategoryLink(categoryTree.parent, `${categoryTree.slug}/${name}`)
    } else {
      return `/${name.toLowerCase().slice(0, -1)}`
    }
  }

  public getBadge (key: AllowedAttributes): ProductBadgeProps {
    if (!this.badgeRegistry) {
      throw new Error('Missing badge registry')
    }

    const badge = this.badgeRegistry[key] ?? this.badgeRegistry.default
    const size = (typeof badge.size === 'string')
      ? badge.size
      : (this.isMobile() ? badge.size.mobile : badge.size.desktop)

    return { ...badge, size }
  }

  public async handleFavoriteAction (): Promise<void> {
    if (!this.isWaitingForAuth && !this.authService?.check() && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector)
      return
    }

    if (!this.isFavorite) {
      return await this.addToFavorite()
    }

    await this.removeFromFavorite()
  }

  public initialVariantSlug (): Slug {
    const slug = this.$route.query.variant
    let slugs: Record<string, string> = {}
    let full: string

    if (!slug || Array.isArray(slug)) {
      const selectors = Object.entries(this.product.variantSelector || {})
      const parts: string[] = []

      for (const [key, selector] of selectors) {
        if (selector.length > 0) {
          if (selector[0] && 'slug' in selector[0]) {
            slugs[key] = selector[0].slug
            parts.push(selector[0].slug)
          }
        }
      }

      full = parts.length ? parts.join('-') : '_'
    } else {
      slugs = this.product.variants[slug].identifier
      full = slug
    }

    return {
      ...slugs,
      full
    }
  }

  public leaveReview (): void {
    if (!this.authCheck() && this.drawerConnector) {
      return openAuthDrawer(this.drawerConnector)
    }

    const variantWithColor = this.product.variantSelector?.color?.find(({ slug }) => {
      return slug === this.variant.identifier.color
    })

    if (!variantWithColor) {
      return
    }

    this.modalConnector.open(Modals.AddReviewModal, {
      title: this.variant.attributes[AllowedAttributes.ProductLine],
      description: this.shouldDisplayReviewWithDescription ? this.variant.name : '',
      variantHex: variantWithColor.value,
      variant: 'color',
      sku: this.variant.sku
    })
  }

  public async onAddToCart (): Promise<void> {
    if (!this.cartService) {
      return
    }

    this.addToCartBtnLoading = true

    try {
      if (this.isInCart) {
        await this.updateInCart(this.variant, this.quantity)
      } else {
        await this.addToCart(
          this.variant,
          this.quantity,
          true,
          this.modalSize,
          this.isMobile() ? this.useDrawer : false
        )
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToCartBtnLoading = false
    }
  }

  public onNotifyMe (): void {
    this.isNotificationFormVisible = true
  }

  public async removeFromFavorite (): Promise<void> {
    this.addToFavoriteBtnLoading = true

    try {
      await this.remove(this.variant.sku)
      this.isFavorite = false
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  public setVariant (slug: string): void {
    const foundVariant = this.product.variants[slug]
    if (!foundVariant) {
      return
    }

    this.currentVariant = foundVariant
  }

  public setVariantOnMount (): void {
    this.currentVariantSlug = this.initialVariantSlug()
    this.setVariant(this.currentVariantSlug.full)
  }

  public updateVariant (slug: string): void {
    this.setVariant(slug)
    this.checkIsFavoriteVariant()

    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        variant: slug
      }
    })
  }

  /**
   * Checks whether every possible gifts are unavailable. Necessary to hide `GiftsBox` partial
   * @protected
   */
  protected async checkGiftsAvailability (): Promise<void> {
    if (!this.currentVariant) {
      return
    }

    const possibleGifts = this.currentVariant.attributes[AllowedAttributes.GiftsSku] as string[]

    if (!possibleGifts || possibleGifts.length === 0) {
      return
    }

    try {
      const gifts = await this.productsRepository.loadProductsBySkus(possibleGifts)

      if (!gifts || gifts.length === 0) {
        return
      }

      this.hasUnavailableGifts = gifts.every((gift) => Object.values(gift.variants)[0].isAvailable && Object.values(
        gift.variants)[0].sellableQuantity > 0)
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected createPriceNameObserver (): void {
    if (!this.productHeaderContentRef) {
      return
    }

    const observer = new IntersectionObserver(this.handlePriceNameDisplay, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    })
    observer.observe(this.productHeaderContentRef)
  }

  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.variant || typeof this.variant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.variant.attributes
      ? this.variant.attributes[attribute] as R : undefined
  }

  protected async checkVariantAvailability (): Promise<void> {
    if (!this.variant || !this.doubleCheckStockStatus) {
      return
    }

    try {
      const stockStatus = await this.productStockRepository.getProductsSellableQuantity([this.variant.sku])
      this.stockStatus = stockStatus[0].sellableQuantity
      this.addToCartBtnLoading = false
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected handlePriceNameDisplay (entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      this.renderPriceNameBar = !entry.isIntersecting
    })
  }

  protected initShowTimer () {
    if (!this.siteService) {
      return
    }

    if (!this.hasDeliveryTimer) {
      this.showTimer = false
      return
    }

    const shippingTimer = this.siteService.getProperty('shippingTimer') as ShippingTimerData | undefined
    const { shouldShowTimer } = showDeliveryTimer(shippingTimer)

    this.showTimer = shouldShowTimer
    this.limitHours = shippingTimer ? shippingTimer.limitHours : '17'
  }

  /**
   * Loads TrustedShop product reviews by sku
   * @protected
   */
  protected async loadReviewsBySku (): Promise<void> {
    if (!this.product) {
      return
    }

    this.eventBus.emit('product:trustedShop-global-loading', true)

    const skus: string[] = []

    for (const variant of Object.values(this.product.variants)) {
      skus.push(variant.sku)
    }

    if (skus.length === 0) {
      return
    }

    try {
      await this.loadProductReviews(skus)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.eventBus.emit('product:trustedShop-global-loading', false)
    }
  }

  protected notify (message: string, type: ToastType): void {
    this.showToast(message, type)
  }

  @Watch('wishlist')
  private onWishlist (): void {
    if (this.wishlist) {
      this.checkIsFavoriteVariant()
    }
  }
}

export default ProductHeader
