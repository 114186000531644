











































import { Component, Vue, Prop } from 'vue-property-decorator'

import { Variant as ProductVariant } from '../../../../../contexts'

import { units } from '../../../config/units'
import Price from '../../../../shared/molecules/Price/Price.vue'

@Component<SimplePrice>({
  name: 'SimplePrice',
  components: { Price }
})
export class SimplePrice extends Vue {
  @Prop({ type: Object, required: true })
  public variant!: ProductVariant<string>

  @Prop({ type: Boolean, required: true })
  public shouldDisplayUnits!: boolean

  @Prop({ type: Boolean, required: true })
  public hasDiscount!: boolean

  @Prop({ type: Boolean, required: true })
  public shouldDisplayNetto!: boolean

  @Prop({ type: Boolean, required: true })
  public shouldDisplayRegularPriceForDiscount!: boolean

  @Prop({ type: Boolean, required: true })
  public shouldDisplayOmnibus!: boolean

  // todo: units - config?
  public variantUnit: string = ''
  public variantVolume: number = 0
  public units: Record<string, { volume: number; label: string }> = units

  public get calculateVariantVolume () {
    if (!this.variant.attributes.size) {
      this.variantVolume = 1
      this.variantUnit = 'szt.'
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const capacity = this.variant.attributes.size.split(' ')
      this.variantVolume = capacity[0]
      this.variantUnit = capacity[1]
    }

    if (this.units[this.variantUnit] === undefined) {
      return false
    }

    const u = this.units[this.variantUnit].volume
    const volume = (u * this.variant.price.finalPrice) / this.variantVolume

    return volume.toFixed(2)
  }

  public get calculateBasicPricePercents () {
    const calc = Math.round(100 - ((this.variant.price.finalPrice * 100) / this.variant.price.regularPrice))
    return this.calculatePercents(calc)
  }

  public get calculateOmnibusPercents () {
    if (!this.variant.price.previousPrice) {
      return this.calculatePercents(0)
    }
    const calc = Math.round(100 - ((this.variant.price.finalPrice * 100) / this.variant.price.previousPrice))
    return this.calculatePercents(calc)
  }

  public get regularPrice () {
    return this.shouldDisplayNetto
      ? this.variant.price.regularPriceNet
      : this.variant.price.regularPrice
  }

  public calculatePercents (value: number) {
    if (value === 0) {
      return value + '%'
    }

    if (value < 100 && value > 0) {
      return '-' + value + '%'
    }

    return '+' + Math.abs((value)) + '%'
  }
}

export default SimplePrice
