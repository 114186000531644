



















import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ProductHeader } from '../../../front/products/organisms/ProductHeader'

import { ProductHeaderModule } from '../ProductHeader.contracts'
import { ProductHeaderGratis } from '../../../front/products/organisms/ProductHeaderGratis'
import { ProductData } from '../../../contexts'

/**
 * Container component for the `ProductHeaderModuleUi`.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */

@Component<ProductHeaderModuleUi>({
  name: 'ProductHeaderModuleUi',
  components: { ProductHeader, ProductHeaderGratis }
})

export class ProductHeaderModuleUi extends AbstractModuleUi<ProductHeaderModule> {
  protected detectedIfGift (sku: string): boolean {
    if (!sku) {
      return false
    }

    const keywords = ['gratis', 'free', 'prezent']

    const toTest: string = sku.toLowerCase()

    for (const k of keywords) {
      if (toTest.indexOf(k) >= 0) return true
    }

    return false
  }

  public get isGratis (): boolean {
    if (!this.data.content.product) {
      return false
    }
    const getFirstVariant = (product: ProductData) => {
      return Object.values(product.variants)[0]
    }
    const variant = getFirstVariant(this.data.content.product)

    return this.detectedIfGift(variant.sku)
  }
}

export default ProductHeaderModuleUi
